import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import CurrencySelector from '../core/components/CurrencySelector';
import MetricSelector from '../core/components/MetricSelector';
import Table from '../core/components/Table';

const metalNames: { [key: string]: string } = {
    XAU: 'GOLD',
    XAG: 'SILVER',
    XPT: 'PLATINUM',
    XPD: 'PALLADIUM',
    XRH: 'RHODIUM',
};

const RateTable = ({
    source: { id: source, name },
    currencyState,
    metricState,
}: {
    source: { id: string; name: string };
    metricState: [string, (newValue: string) => void];
    currencyState: [string, (newValue: string) => void];
}) => {
    const [currency] = currencyState;
    const [metric] = metricState;

    const { data, error, loading } = useQuery<{
        latestCommodityRates: {
            items: {
                source: string;
                commodity: string;
                rate: string;
                ask: string;
                bid: string;
                changeValue?: string;
                changePercentage?: string;
                low?: string;
                high?: string;
                recorded: string;
                created: string;
            }[];
        };
    }>(
        gql`
            query latestCommodityRates($source: String!, $purity: Purity!, $metric: Metric!, $currency: Currency!) {
                latestCommodityRates(source: $source, purity: $purity, metric: $metric, currency: $currency) {
                    items {
                        source
                        commodity
                        rate
                        ask
                        bid
                        changeValue
                        changePercentage
                        low
                        high
                        recorded
                        created
                    }
                }
            }
        `,
        {
            variables: {
                source,
                purity: 'purity24k',
                metric,
                currency,
            },
            pollInterval: 10_000,
        },
    );

    if (error) {
        return <>{error.toString()}</>;
    }

    if (loading || !data) {
        return null;
    }

    const {
        latestCommodityRates: { items },
    } = data;

    // TODO: obsolete this
    if (!items.length) {
        return null;
    }

    return (
        <div style={{ flexGrow: '1' }} className="table-responsive">
            <h2 style={{ textAlign: 'center' }}>{name.replace('Kitco - ', '')}</h2>
            {!!items.length && (
                <Table>
                    <thead>
                        <tr>
                            <th style={{ padding: 0 }} colSpan={9}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: '#2a4144',
                                        padding: '0.5rem',
                                    }}
                                >
                                    <CurrencySelector state={currencyState} />
                                    <MetricSelector state={metricState} />
                                    <h3 style={{ textTransform: 'uppercase', color: '#69a642' }}>Market is open</h3>
                                    <div style={{ fontWeight: 'normal' }}>
                                        Will <span style={{ textTransform: 'uppercase', color: '#c91420' }}>close</span>{' '}
                                        in 2hrs. 32mins.
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th scope="col">Commodity</th>
                            {!!+items[0].rate && (
                                <th scope="col" style={{ textAlign: 'right' }}>
                                    Rate
                                </th>
                            )}
                            {!!+items[0].ask && (
                                <th scope="col" style={{ textAlign: 'right' }}>
                                    Ask
                                </th>
                            )}
                            {!!+items[0].bid && (
                                <th scope="col" style={{ textAlign: 'right' }}>
                                    Bid
                                </th>
                            )}
                            {items[0].low && (
                                <th scope="col" style={{ textAlign: 'right' }}>
                                    Low
                                </th>
                            )}
                            {items[0].high && (
                                <th scope="col" style={{ textAlign: 'right' }}>
                                    High
                                </th>
                            )}
                            {(items[0].changePercentage || items[0].changePercentage) && (
                                <th colSpan={2} scope="col" style={{ textAlign: 'center' }}>
                                    Change
                                </th>
                            )}
                            <th scope="col" style={{ textAlign: 'right' }}>
                                Date
                            </th>
                            <th scope="col" style={{ textAlign: 'right' }}>
                                Time
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map(rate => (
                            <tr key={[rate.source, rate.commodity, rate.created].join('_')}>
                                <td>
                                    <Link
                                        to={{
                                            pathname: '/commodities',
                                            search: new URLSearchParams({
                                                metric,
                                                currency,
                                                purity: 'purity24k',
                                                source,
                                                commodity: rate.commodity,
                                            }).toString(),
                                        }}
                                    >
                                        {metalNames[rate.commodity]}
                                    </Link>
                                </td>
                                {!!+rate.rate && <td style={{ textAlign: 'right' }}>{rate.rate}</td>}
                                {!!+rate.ask && <td style={{ textAlign: 'right' }}>{rate.ask}</td>}
                                {!!+rate.bid && <td style={{ textAlign: 'right' }}>{rate.bid}</td>}
                                {!!rate.low && <td style={{ textAlign: 'right' }}>{rate.low}</td>}
                                {!!rate.high && <td style={{ textAlign: 'right' }}>{rate.high}</td>}
                                {(rate.changeValue || rate.changePercentage) && (
                                    <>
                                        <td style={{ textAlign: 'right' }}>
                                            {rate.changeValue && (
                                                <>
                                                    <span style={{ color: +rate.changeValue > 0 ? 'green' : 'red' }}>
                                                        {+rate.changeValue > 0 && '+'}
                                                        {rate.changeValue}
                                                    </span>
                                                    <div className={`arrow-${+rate.changeValue > 0 ? 'up' : 'down'}`} />
                                                </>
                                            )}
                                        </td>
                                        <td style={{ textAlign: 'right' }}>
                                            {rate.changePercentage && (
                                                <>
                                                    <span
                                                        style={{ color: +rate.changePercentage > 0 ? 'green' : 'red' }}
                                                    >
                                                        {+rate.changePercentage > 0 && '+'}
                                                        {rate.changePercentage}%
                                                    </span>
                                                    <div
                                                        className={`arrow-${
                                                            +rate.changePercentage > 0 ? 'up' : 'down'
                                                        }`}
                                                    />
                                                </>
                                            )}
                                        </td>
                                    </>
                                )}

                                <td style={{ textAlign: 'right' }}>
                                    {rate.recorded.substring(0, 10).replaceAll('T', ' ')}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    {rate.recorded.substring(11, 16).replaceAll('T', ' ')}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </div>
    );
};

export default RateTable;
