import { useMutation, ApolloError, gql } from '@apollo/client';

/* global location */
/* eslint no-restricted-globals: 0 */

const useLogin = (): [
    (username: string, password: string) => Promise<void>,
    { error?: ApolloError; loading?: boolean },
] => {
    const [login, { loading, error }] = useMutation<{
        login: { accessToken?: string };
    }>(
        gql`
            mutation login($username: String!, $password: String!) {
                login(username: $username, password: $password) {
                    accessToken
                }
            }
        `,
        {
            errorPolicy: 'all',
        },
    );

    return [
        async (username: string, password: string) => {
            const result = await login({
                variables: {
                    username,
                    password,
                },
            });

            if (result.data?.login) {
                const newAccessToken = result.data?.login.accessToken;

                if (!newAccessToken) {
                    throw new Error('Login failed');
                }

                localStorage.setItem('accessToken', newAccessToken);
                localStorage.setItem('accessTokenTime', Date.now().toString());
                location.reload();
            }
        },
        {
            loading,
            error,
        },
    ];
};

export default useLogin;
