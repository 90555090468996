import { gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './core/components/Layout';
import UpdateNotifier from './core/components/UpdateNotifier';
import Home from './home/Home';
import { AppContext } from './main/context';
import CommodityRateDashboard from './rate/components/CommodityRateDashboard';
import CurrencyRateDashboard from './rate/components/CurrencyRateDashboard';
import LoginForm from './user/components/LoginForm';

const valuesQuery = gql`
    query values {
        sources {
            id
            name
        }
        purities {
            id
            name
        }
        currencies {
            id
            name
        }
        metrics {
            id
            name
        }
        commodities {
            id
            name
        }
    }
`;

const currentUserQuery = gql`
    query currentUserAndValues {
        currentUser {
            username
        }
    }
`;

const App = () => {
    const { data, loading, error } = useQuery<{
        currentUser?: {
            username: string;
        }[];
    }>(currentUserQuery);

    if (loading) {
        return <>...</>;
    }

    if (error) {
        const errorCodes = Array.from(new Set(error.graphQLErrors.map(item => item.extensions.code)));
        if (errorCodes.length === 1 && errorCodes[0] === 'FORBIDDEN') {
            return <LoginForm />;
        }
        return <>{error.toString()}</>;
    }

    if (!data) {
        return <>No data</>;
    }

    const { currentUser } = data;

    if (!currentUser) {
        return <LoginForm />;
    }

    return (
        <Routes>
            <Route index element={<Home />} />
            <Route path="commodities" element={<CommodityRateDashboard />} />
            <Route path="currencies" element={<CurrencyRateDashboard />} />
        </Routes>
    );
};

const Main = () => {
    const [isLoggedIn] = useState(!!localStorage.getItem('accessToken'));
    const {
        data = {
            sources: [],
            purities: [],
            currencies: [],
            metrics: [],
            commodities: [],
        },
        loading,
        error,
    } = useQuery<{
        sources: {
            id: string;
            name: string;
        }[];
        purities: {
            id: string;
            name: string;
        }[];
        currencies: {
            id: string;
            name: string;
        }[];
        metrics: {
            id: string;
            name: string;
        }[];
        commodities: {
            id: string;
            name: string;
        }[];
    }>(valuesQuery, {
        skip: !isLoggedIn,
    });

    if (loading || (!data && isLoggedIn)) {
        return <>...</>;
    }

    if (error && !isLoggedIn) {
        return <>{error.toString()}</>;
    }

    return (
        <AppContext.Provider value={{ ...data, isLoggedIn }}>
            <Router>
                <Layout>{isLoggedIn ? <App /> : <LoginForm />}</Layout>
            </Router>
            <UpdateNotifier />
        </AppContext.Provider>
    );
};

export default Main;
