import { useSearchParams } from 'react-router-dom';

const createSearchParamUpdater =
    (
        searchParams: ReturnType<typeof useSearchParams>[0],
        setSearchParams: ReturnType<typeof useSearchParams>[1],
        paramSortOrder: string[],
    ) =>
    (update: {}) => {
        const entries: [string, string][] = Object.entries({
            ...Object.fromEntries(searchParams.entries()),
            ...update,
        });
        entries.sort(([a], [b]) => paramSortOrder.indexOf(a) - paramSortOrder.indexOf(b));
        const params: { [key: string]: string } = Object.fromEntries(entries);
        setSearchParams(params);
    };

export default createSearchParamUpdater;
