import { useContext } from 'react';
import { AppContext } from '../../main/context';
import Select from './Select';

const MetricSelector = ({ state: [value, setValue] }: { state: [string, (newValue: string) => void] }) => {
    const { metrics } = useContext(AppContext);

    return (
        <Select
            value={value}
            onChange={event => {
                setValue(event.target.value);
            }}
            className="form-control"
        >
            <option>Metric</option>
            {metrics.map(metric => (
                <option
                    key={metric.id}
                    value={metric.id}
                    disabled={!['gr', 'kg', 'tola', 'ounceTroy'].includes(metric.id)}
                >
                    {metric.name}
                </option>
            ))}
        </Select>
    );
};

export default MetricSelector;
