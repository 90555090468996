import { DateTime } from 'luxon';
import { gql, useQuery } from '@apollo/client';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';
import Highstock from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

import styles from './RateDashboard.module.css';
import createSearchParamUpdater from '../../core/util/createSearchParamUpdater';

const CommodityResultList = ({
    source,
    commodity,
    purity,
    metric,
    currency,
}: {
    source: string;
    commodity: string;
    purity: string;
    metric: string;
    currency: string;
}) => {
    const { data } = useQuery<{
        commodityRates: {
            items: {
                source: string;
                commodity: string;
                ask: string;
                bid: string;
                recorded: string;
                created: string;
            }[];
        };
    }>(
        gql`
            query commodityRates(
                $source: String!
                $commodity: Commodity!
                $purity: Purity!
                $metric: Metric!
                $currency: Currency!
            ) {
                commodityRates(
                    source: $source
                    commodity: $commodity
                    purity: $purity
                    metric: $metric
                    currency: $currency
                ) {
                    items {
                        source
                        commodity
                        ask
                        bid
                        recorded
                        created
                    }
                }
            }
        `,
        {
            variables: {
                source,
                commodity,
                purity,
                metric,
                currency,
            },
            skip: !source || !commodity || !purity || !metric || !currency,
            pollInterval: 10_000,
        },
    );

    if (!data) {
        return null;
    }

    const {
        commodityRates: { items },
    } = data;

    const options2 = {
        chart: {
            height: 800,
        },
        title: {
            text: 'Chart 2',
        },
        xAxis: {
            ordinal: false,
        },
        series: [
            {
                name: 'Ask',
                data: items.map(item => [DateTime.fromISO(item.recorded).toJSDate().getTime(), +item.ask]),
            },
            {
                name: 'Bid',
                data: items.map(item => [DateTime.fromISO(item.recorded).toJSDate().getTime(), +item.bid]),
            },
        ],
    };

    return <HighchartsReact highcharts={Highstock} constructorType={'stockChart'} options={options2} />;
};

const paramOrder = ['source', 'commodity', 'purity', 'metric', 'currency'];

const CommodityRateDashboard = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const source = searchParams.get('source') || '';
    const commodity = searchParams.get('commodity') || '';
    const purity = searchParams.get('purity') || '';
    const metric = searchParams.get('metric') || '';
    const currency = searchParams.get('currency') || '';

    const updateSearch = createSearchParamUpdater(searchParams, setSearchParams, paramOrder);

    return (
        <>
            <div className={clsx('mb-3 mt-3', styles.dashboardControl)}>
                <select
                    value={source}
                    onChange={event => {
                        updateSearch({ source: event.target.value });
                    }}
                    className="form-control"
                >
                    <option>Source</option>
                    <option>Kitco - New York Spot Price</option>
                    <option>Kitco - The World Spot Price</option>
                    <option disabled>London Fix</option>
                    <option disabled>Kitco</option>
                    <option disabled>Monex</option>
                    <option disabled>BNR</option>
                </select>
                <select
                    value={commodity}
                    onChange={event => {
                        updateSearch({ commodity: event.target.value });
                    }}
                    className="form-control"
                >
                    <option>Commodity</option>
                    <option value="XAU">Gold</option>
                    <option value="XAG">SILVER</option>
                    <option value="XPT">PLATINUM</option>
                    <option value="XPD">PALLADIUM</option>
                    <option value="XRH">RHODIUM</option>
                </select>
                <select
                    value={purity}
                    onChange={event => {
                        updateSearch({ purity: event.target.value });
                    }}
                    className="form-control"
                >
                    <option>Purity</option>
                    <option value="purity24k">24K</option>
                    <option disabled value="purity22k">
                        22K
                    </option>
                    <option disabled value="purity21k">
                        21K
                    </option>
                    <option disabled value="purity18k">
                        18K
                    </option>
                </select>
                <select
                    value={metric}
                    onChange={event => {
                        updateSearch({ metric: event.target.value });
                    }}
                    className="form-control"
                >
                    <option>Metric</option>
                    <option value="ounceTroy" disabled>
                        Ounce - Troy
                    </option>
                    <option value="gr">Gr</option>
                    <option value="kg" disabled>
                        Kg
                    </option>
                    <option value="pennyweightDWT" disabled>
                        Pennyweight (DWT)
                    </option>
                    <option value="tola" disabled>
                        Tola
                    </option>
                    <option value="taelHK" disabled>
                        Tael (HK)
                    </option>
                </select>
                <select
                    value={currency}
                    onChange={event => {
                        updateSearch({ currency: event.target.value });
                    }}
                    className="form-control"
                >
                    <option>Currency</option>
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                    <option value="RON">RON</option>
                </select>
            </div>
            {source && commodity && purity && metric && currency && (
                <CommodityResultList
                    source={source}
                    commodity={commodity}
                    purity={purity}
                    metric={metric}
                    currency={currency}
                />
            )}
        </>
    );
};

export default CommodityRateDashboard;
