/* eslint @typescript-eslint/ban-types: 0 */
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, Observable, Observer } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

// const baseUrl = 'http://localhost:5001';
const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext(() => {
    const result: {
        headers: {
            'X-Access-Token'?: string;
        };
    } = {
        headers: {},
    };

    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
        result.headers['X-Access-Token'] = accessToken;
    }

    return result;
});

/* eslint-disable @typescript-eslint/no-non-null-assertion */

let globalQueryObserver: Observer<unknown>;

export const globalQueryObservable = new Observable(observer => {
    globalQueryObserver = observer;
});

const indicatorLink = () =>
    new ApolloLink((operation, forward) => {
        return new Observable(observer => {
            const subscription = forward(operation).subscribe({
                next: result => {
                    globalQueryObserver?.next!(result);
                    observer.next(result);
                },
                error: error => {
                    globalQueryObserver?.error!(error);
                    observer.error(error);
                },
                complete: () => {
                    globalQueryObserver?.complete!();
                    observer.complete();
                },
            });

            return () => {
                subscription.unsubscribe();
            };
        });
    });

export const client = new ApolloClient({
    link: indicatorLink().concat(authLink.concat(httpLink)),
    cache: new InMemoryCache(),
    defaultOptions: {
        query: {
            fetchPolicy: 'no-cache',
        },
    },
});
