import { DateTime } from 'luxon';
import { gql, useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import Highstock from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import styles from './RateDashboard.module.css';
import createSearchParamUpdater from '../../core/util/createSearchParamUpdater';

const paramOrder = ['from', 'to'];

const CurrencyRateResult = ({ from, to }: { from: string; to: string }) => {
    const { data, error } = useQuery<{
        currencyRates: {
            items: {
                source: string;
                from: string;
                to: string;
                rate: string;
                recorded: string;
                created: string;
            }[];
        };
    }>(
        gql`
            query currencyRates($from: Currency!, $to: Currency!) {
                currencyRates(from: $from, to: $to) {
                    items {
                        source
                        from
                        to
                        rate
                        recorded
                        created
                    }
                }
            }
        `,
        {
            skip: !from || !to,
            variables: { from, to },
            pollInterval: 10_000,
        },
    );

    if (error) {
        return <>{error.toString()}</>;
    }

    if (!data) {
        return null;
    }

    const {
        currencyRates: { items },
    } = data;

    return (
        <>
            <HighchartsReact
                highcharts={Highstock}
                constructorType={'stockChart'}
                options={{
                    title: {
                        text: `${from} - ${to}`,
                    },
                    chart: {
                        height: 700,
                    },
                    xAxis: {
                        ordinal: false,
                    },
                    series: [
                        {
                            name: `${from} - ${to}`,
                            data: items.map(item => [DateTime.fromISO(item.recorded).toJSDate().getTime(), +item.rate]),
                        },
                    ],
                }}
            />
        </>
    );
};

const CurrencyRateDashboard = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const from = searchParams.get('from') || '';
    const to = searchParams.get('to') || '';

    const updateSearch = createSearchParamUpdater(searchParams, setSearchParams, paramOrder);

    return (
        <>
            <div className={clsx('mb-3 mt-3', styles.dashboardControl)}>
                <select
                    value={from}
                    onChange={event => {
                        updateSearch({ from: event.target.value });
                    }}
                    className="form-control"
                >
                    <option>From</option>
                    <option>USD</option>
                    <option>EUR</option>
                    <option>GBP</option>
                    <option disabled>RON</option>
                </select>
                <select
                    value={to}
                    onChange={event => {
                        updateSearch({ to: event.target.value });
                    }}
                    className="form-control"
                >
                    <option>To</option>
                    <option>USD</option>
                    <option>EUR</option>
                    <option>GBP</option>
                    <option>RON</option>
                </select>
            </div>

            {from && to && <CurrencyRateResult from={from} to={to} />}
        </>
    );
};

export default CurrencyRateDashboard;
