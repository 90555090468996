import { ReactNode } from 'react';
import '@istvan.xyz/ui/style.css';
import '@istvan.xyz/ui/page.css';
import './Layout.css';
import { NavLink } from 'react-router-dom';
import StatusIndicator from './StatusIndicator';

const Layout = ({ children }: { children: ReactNode }) => (
    <>
        {false && (
            <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap shadow justify-content-lg-start">
                <a className="navbar-brand col-md-3 col-lg-1 me-0 px-3" href="#">
                    Rates
                </a>
                <ul className="nav mb-2 mb-md-0">
                    <li>
                        <NavLink to="/" className="nav-link px-2 text-white">
                            Home
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/commodities" className="nav-link px-2 text-white">
                            Commodities
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/currencies" className="nav-link px-2 text-white">
                            Currencies
                        </NavLink>
                    </li>
                </ul>
            </header>
        )}

        <main style={{ padding: '0 1rem' }}>{children}</main>
        <StatusIndicator />
    </>
);

export default Layout;
