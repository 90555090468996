import { ReactNode } from 'react';
import styles from './Table.module.css';

const Table = ({ children }: { children: ReactNode }) => (
    <table className={styles.table} cellSpacing="0">
        {children}
    </table>
);

export default Table;
