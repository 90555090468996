import { useEffect, useState } from 'react';

const mainScriptUrl = document.getElementsByTagName('script')[0].src;

const UpdateNotifier = () => {
    const [isUpToDate, setIsUpToDate] = useState(true);
    useEffect(() => {
        const updateCheckInterval = setInterval(() => {
            (async () => {
                const response = await fetch(mainScriptUrl);
                /* eslint-disable-next-line no-console */
                console.log(response.status);
                if (response.status === 404) {
                    clearTimeout(updateCheckInterval);
                    setIsUpToDate(false);
                }
            })().catch(error => {
                throw error;
            });
        }, 30_000);

        return () => {
            clearTimeout(updateCheckInterval);
        };
    });

    if (isUpToDate) {
        return null;
    }
    return (
        <div className="modal" style={{ display: 'block' }} tabIndex={-1} role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">New version available</h5>
                    </div>
                    <div className="modal-body">
                        <p>Please reload.</p>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                /* eslint-disable-next-line no-restricted-globals */
                                location.reload();
                            }}
                            className="btn btn-primary"
                        >
                            Reload
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateNotifier;
