import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { globalQueryObservable } from '../../main/client';

const StatusIndicator = () => {
    const [lastSuccess, setLastSuccess] = useState<DateTime>(DateTime.local());
    const [lastError, setLastError] = useState<DateTime>(DateTime.fromMillis(0));
    const [, setNow] = useState(Date.now());

    useEffect(() => {
        const subscription = globalQueryObservable.subscribe(
            () => {
                setLastSuccess(DateTime.local());
            },
            queryError => {
                /* eslint-disable-next-line no-console */
                console.error(queryError);
                setLastError(DateTime.local());
            },
            () => {},
        );

        const interval = setInterval(() => {
            setNow(Date.now());
        }, 10_000);

        return () => {
            clearInterval(interval);
            subscription.unsubscribe();
        };
    });

    const lastSuccessDiff = -(lastSuccess.diffNow('seconds').seconds || 0);
    const lastErrorDiff = -(lastError.diffNow('seconds').seconds || 0);

    if (lastSuccessDiff > 30 || lastErrorDiff < 10) {
        return (
            <div
                title={lastSuccessDiff.toString()}
                style={{
                    zIndex: 999999,
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    width: 10,
                    height: 10,
                    borderRadius: 5,
                    backgroundColor: '#F00',
                }}
            ></div>
        );
    }

    return (
        <div
            title={lastSuccess?.toISO()}
            style={{
                zIndex: 999999,
                position: 'absolute',
                top: 10,
                right: 10,
                width: 10,
                height: 10,
                borderRadius: 5,
                backgroundColor: '#0F0',
            }}
        ></div>
    );
};

export default StatusIndicator;
