import { createContext } from 'react';

export interface AppContextValues {
    isLoggedIn: boolean;
    sources: {
        id: string;
        name: string;
    }[];
    purities: {
        id: string;
        name: string;
    }[];
    currencies: {
        id: string;
        name: string;
    }[];
    metrics: {
        id: string;
        name: string;
    }[];
    commodities: {
        id: string;
        name: string;
    }[];
}

export const AppContext = createContext<AppContextValues>({
    isLoggedIn: false,
    sources: [],
    purities: [],
    currencies: [],
    metrics: [],
    commodities: [],
});
