import { useContext } from 'react';
import { AppContext } from '../../main/context';
import Select from './Select';

const labels: { [key: string]: string } = {
    USD: '🇺🇸 US Dollar',
    EUR: '🇪🇺 Euro',
    GBP: '🇬🇧 British Pound',
};

const CurrencySelector = ({ state: [value, setValue] }: { state: [string, (newValue: string) => void] }) => {
    const { currencies } = useContext(AppContext);

    return (
        <Select
            value={value}
            onChange={event => {
                setValue(event.target.value);
            }}
            className="form-control"
        >
            <option>Currency</option>
            {currencies.map(item => (
                <option key={item.id} value={item.id} disabled={item.id === 'RON'}>
                    {labels[item.id] || item.name}
                </option>
            ))}
        </Select>
    );
};

export default CurrencySelector;
