import { ApolloProvider } from '@apollo/client';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import { apply } from './core/components/HighchartsTheme';
import Main from './Main';
import { client } from './main/client';

const appRoot = document.getElementById('root');

if (appRoot) {
    apply();

    render(
        process.env.NODE_ENV === 'development' ? (
            <StrictMode>
                <ApolloProvider client={client}>
                    <Main />
                </ApolloProvider>
            </StrictMode>
        ) : (
            <ApolloProvider client={client}>
                <Main />
            </ApolloProvider>
        ),
        appRoot,
    );
}

export {};
