import { useCallback, useContext, useReducer } from 'react';
import CurrencySelector from '../core/components/CurrencySelector';
import MetricSelector from '../core/components/MetricSelector';
import { AppContext } from '../main/context';
import RateTable from './RateTable';

interface SelectionState {
    globalValue: string;
    localValues: {
        [key: string]: string;
    };
}

type SelectionAction = { type: 'setGlobal'; value: string } | { type: 'setLocal'; id: string; value: string };

const stateReducer = (state: SelectionState, action: SelectionAction): SelectionState => {
    const { type } = action;
    switch (type) {
        case 'setGlobal':
            return {
                ...state,
                globalValue: action.value,
                localValues: {},
            };
        case 'setLocal':
            return {
                ...state,
                localValues: {
                    ...state.localValues,
                    [action.id]: action.value,
                },
                globalValue: '',
            };
        default:
            throw new Error(`Invalid action type ${type}`);
    }
};

const defaultMetric = 'gr';
const defaultCurrency = 'USD';

const Home = () => {
    const { sources } = useContext(AppContext);

    const [metricState, dispatchMetricAction] = useReducer(stateReducer, {
        globalValue: 'gr',
        localValues: {},
    });

    const [currencyState, dispatchCurrencyAction] = useReducer(stateReducer, {
        globalValue: 'USD',
        localValues: {},
    });

    const setGlobalMetric = useCallback((value: string) => {
        dispatchMetricAction({
            type: 'setGlobal',
            value,
        });
    }, []);

    const setGlobalCurrency = useCallback((value: string) => {
        dispatchCurrencyAction({
            type: 'setGlobal',
            value,
        });
    }, []);

    return (
        <div style={{ paddingTop: '2rem' }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: '#172d59',
                    padding: '1rem',
                    border: 'var(--app-border-size) solid var(--app-border-color)',
                    borderRightColor: '#334567',
                    alignContent: 'center',
                }}
            >
                <MetricSelector state={[metricState.globalValue, setGlobalMetric]} />
                <div style={{ width: '20px' }}></div>
                <CurrencySelector state={[currencyState.globalValue, setGlobalCurrency]} />
                <div style={{ width: '20px' }}></div>
                <div style={{ textTransform: 'uppercase', fontSize: '1.3rem' }}>Change settings for all tables</div>
            </div>
            <div style={{ paddingTop: '1rem' }}></div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '2rem' }}>
                {sources.map(source => (
                    <RateTable
                        key={source.id}
                        source={source}
                        metricState={[
                            metricState.localValues[source.id] || metricState.globalValue || defaultMetric,
                            (value: string) => {
                                dispatchMetricAction({
                                    type: 'setLocal',
                                    id: source.id,
                                    value,
                                });
                            },
                        ]}
                        currencyState={[
                            currencyState.localValues[source.id] || currencyState.globalValue || defaultCurrency,
                            (value: string) => {
                                dispatchCurrencyAction({
                                    type: 'setLocal',
                                    id: source.id,
                                    value,
                                });
                            },
                        ]}
                    />
                ))}
            </div>
        </div>
    );
};

export default Home;
