import { useState } from 'react';
import useLogin from '../../core/hooks/useLogin';

const LoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [login, { error }] = useLogin();
    return (
        <div className="text-center">
            {error && <div>{error.toString()}</div>}
            <form
                className="form-login"
                onSubmit={event => {
                    event.preventDefault();
                    login(username, password).catch(loginError => {
                        throw loginError;
                    });
                }}
            >
                <h1 className="h3 mb-3 fw-normal">Please sign in</h1>
                <label htmlFor="inputUsername" className="visually-hidden">
                    Username
                </label>
                <input
                    value={username}
                    onChange={event => {
                        setUsername(event.target.value);
                    }}
                    type="text"
                    id="inputUsername"
                    className="form-control"
                    placeholder="Username"
                    required
                    autoFocus
                />
                <label htmlFor="inputPassword" className="visually-hidden">
                    Password
                </label>
                <input
                    value={password}
                    onChange={event => {
                        setPassword(event.target.value);
                    }}
                    type="password"
                    id="inputPassword"
                    className="form-control"
                    placeholder="Password"
                    required
                />
                <div className="checkbox mb-3">
                    <label>
                        <input type="checkbox" value="remember-me" /> Remember me
                    </label>
                </div>
                <div className="d-grid gap-1">
                    <button className="btn btn-lg btn-primary" type="submit">
                        Sign in
                    </button>
                </div>
            </form>
        </div>
    );
};

export default LoginForm;
